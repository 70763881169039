<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      class="w-11/12 bg-white rounded-xl shadow flex flex-col"
      v-else-if="!isMobileView"
    >
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Timesheet Details
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div class="p-4 flex flex-col gap-4">
        <div class="flex gap-4">
          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-sm text-left font-normal">Date</div>
            <div class="text-neutral-900 text-sm text-left font-semibold">
              {{
                helpers.formatDateNoTimezone(
                  data[0].Timesheet ? data[0].Timesheet.date_generated : ""
                )
              }}
            </div>
          </div>

          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">
              Assignee
            </div>
            <div class="flex gap-1 mt-1">
              <img
                class="w-6 h-6 rounded-full"
                :src="data[0].Timesheet ? data[0].Timesheet.User.photo : ''"
                :title="
                  data[0].Timesheet
                    ? data[0].Timesheet.User.name +
                      ' ' +
                      data[0].Timesheet.User.last_name
                    : ''
                "
              />
            </div>
          </div>

          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">
              Total Hours
            </div>
            <div
              class="text-neutral-900 text-left text-base font-normal leading-relaxed"
            >
              {{
                helpers
                  .convertSecondsToDecimalHours(
                    data[0].Timesheet ? data[0].Timesheet.total_time : ""
                  )
                  .toFixed(2)
              }}
              Hrs
            </div>
          </div>
          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">
              Range Start Date
            </div>
            <div
              class="text-neutral-900 text-left text-base font-normal leading-relaxed"
            >
              {{
                helpers.formatDateToDDMMMYYYY(
                  data[0].Timesheet ? data[0].Timesheet.range_start_date : ""
                )
              }}
            </div>
          </div>
          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">
              Range End Date
            </div>
            <div
              class="text-neutral-900 text-left text-base font-normal leading-relaxed"
            >
              {{
                helpers.formatDateToDDMMMYYYY(
                  data[0].Timesheet ? data[0].Timesheet.range_end_date : ""
                )
              }}
            </div>
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Status</div>
          <div
            v-if="data[0].Timesheet.status == 'Submitted'"
            class="px-3 pt-1 mix-blend-multiply bg-blue-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-blue-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data[0].Timesheet.status }}
            </div>
          </div>
          <div
            v-if="data[0].Timesheet.status == 'Approved'"
            class="px-3 pt-1 mix-blend-multiply bg-emerald-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-emerald-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data[0].Timesheet.status }}
            </div>
          </div>
          <div
            v-if="data[0].Timesheet.status == 'Rejected'"
            class="px-3 pt-1 mix-blend-multiply bg-red-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-red-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data[0].Timesheet.status }}
            </div>
          </div>
        </div>

        <div class="p-4 flex flex-col gap-4 overflow-y-auto">
          <div
            class="grid gap-4 font-semibold text-slate-700"
            style="grid-template-columns: 10% 20% 30% 15% 15% 10%"
          >
            <div class="text-left">Project Name</div>
            <div class="text-left">Activity</div>
            <div class="text-left">Activity Details</div>
            <div class="text-left">Start Date</div>
            <div class="text-left">Complete Date</div>
            <div class="text-left">Hours Worked</div>
          </div>
          <div v-if="data.length" class="max-h-80">
            <div
              v-for="(item, index) in data"
              :key="index"
              class="grid gap-4 border-t border-slate-200 pt-2"
              style="grid-template-columns: 10% 20% 30% 15% 15% 10%"
            >
              <div class="text-left">
                {{ item.Task ? item.Task.Project.name : "" }}
              </div>
              <div class="text-left">{{ item.Task ? item.Task.name : "" }}</div>
              <div class="text-left">
                {{ item.Task ? item.Task.description : "" }}
              </div>
              <div class="text-left">
                {{
                  item.TimeTracker
                    ? helpers.formatDateToDDMMMYYYY(item.TimeTracker.started_at)
                    : ""
                }}
              </div>
              <div class="text-left">
                {{
                  item.TimeTracker
                    ? helpers.formatDateToDDMMMYYYY(
                        item.TimeTracker.completed_at
                      )
                    : ""
                }}
              </div>
              <div class="text-left">
                {{
                  item.TimeTracker
                    ? helpers
                        .convertSecondsToDecimalHours(
                          item.TimeTracker.seconds_elapsed
                        )
                        .toFixed(2)
                    : ""
                }}
                Hrs
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="status == 'Submitted' && user_type == 1"
          class="px-4 pt-2 pb-6 justify-end items-center gap-3 inline-flex"
        >
          <button
            @click="rejectTimesheet"
            class="px-3.5 py-[9px] bg-red-400 rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Reject Invoice
            </div>
          </button>

          <button
            @click="aproveTimesheet"
            class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Approve Invoice
            </div>
          </button>
        </div>
      </div>
    </div>
    <div
      class="w-full max-w-sm bg-white rounded-2xl shadow-lg flex flex-col max-h-[80vh] overflow-y-auto"
      v-else
    >
      <!-- Header -->
      <div
        class="p-4 bg-slate-100 rounded-t-xl border-b border-slate-200 flex justify-between items-center"
      >
        <h2 class="text-neutral-900 text-lg font-semibold">
          Timesheet Details
        </h2>
        <button @click="closeModal" class="p-2 bg-gray-400 rounded-full">
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <!-- Información General -->
      <div class="p-4 space-y-4">
        <div class="flex justify-between">
          <span class="text-slate-500 text-sm">Date:</span>
          <span class="text-neutral-900 text-sm font-medium">
            {{
              helpers.formatDateNoTimezone(
                data[0].Timesheet ? data[0].Timesheet.date_generated : ""
              )
            }}
          </span>
        </div>

        <div class="flex justify-between items-center">
          <span class="text-slate-500 text-sm">Assignee:</span>
          <div class="flex items-center gap-2">
            <img
              class="w-8 h-8 rounded-full"
              :src="data[0].Timesheet ? data[0].Timesheet.User.photo : ''"
              alt="Assignee Photo"
            />
            <span class="text-neutral-900 text-sm font-medium">
              {{
                data[0].Timesheet
                  ? data[0].Timesheet.User.name +
                    " " +
                    data[0].Timesheet.User.last_name
                  : ""
              }}
            </span>
          </div>
        </div>

        <div class="flex justify-between">
          <span class="text-slate-500 text-sm">Total Hours:</span>
          <span class="text-neutral-900 text-sm font-medium">
            {{
              helpers
                .convertSecondsToDecimalHours(
                  data[0].Timesheet ? data[0].Timesheet.total_time : 0
                )
                .toFixed(2)
            }}
            Hrs
          </span>
        </div>

        <div class="flex justify-between">
          <span class="text-slate-500 text-sm">Range Start Date:</span>
          <span class="text-neutral-900 text-sm font-medium">
            {{
              helpers.formatDateToDDMMMYYYY(
                data[0].Timesheet ? data[0].Timesheet.range_start_date : ""
              )
            }}
          </span>
        </div>

        <div class="flex justify-between">
          <span class="text-slate-500 text-sm">Range End Date:</span>
          <span class="text-neutral-900 text-sm font-medium">
            {{
              helpers.formatDateToDDMMMYYYY(
                data[0].Timesheet ? data[0].Timesheet.range_end_date : ""
              )
            }}
          </span>
        </div>

        <div class="flex justify-between items-center">
          <span class="text-slate-500 text-sm">Status:</span>
          <span
            :class="{
              'bg-blue-100 text-blue-700':
                data[0].Timesheet.status === 'Submitted',
              'bg-green-100 text-green-700':
                data[0].Timesheet.status === 'Approved',
              'bg-red-100 text-red-700':
                data[0].Timesheet.status === 'Rejected',
            }"
            class="px-3 py-1 rounded-full text-sm font-semibold"
          >
            {{ data[0].Timesheet.status }}
          </span>
        </div>
      </div>

      <!-- Información de la Tabla Convertida en Cards -->
      <div class="flex-1 p-4 space-y-4">
        <div v-if="data.length" class="space-y-4">
          <!-- Cards de cada Item -->
          <div
            v-for="(item, index) in data"
            :key="index"
            class="bg-slate-50 p-4 rounded-lg shadow-sm flex flex-col gap-4 border-t border-slate-200"
          >
            <!-- Project Name -->
            <div class="flex justify-between">
              <div class="font-semibold text-sm text-slate-500">
                Project Name
              </div>
              <div class="text-sm text-neutral-900">
                {{ item.Task ? item.Task.Project.name : "" }}
              </div>
            </div>

            <!-- Activity -->
            <div class="flex justify-between">
              <div class="font-semibold text-sm text-slate-500">Activity</div>
              <div class="text-sm text-neutral-900">
                {{ item.Task ? item.Task.name : "" }}
              </div>
            </div>

            <!-- Activity Details -->
            <div class="flex justify-between">
              <div class="font-semibold text-sm text-slate-500">
                Details
              </div>
              <div class="text-sm text-neutral-900 text-end">
                {{ item.Task ? item.Task.description : "" }}
              </div>
            </div>

            <!-- Start Date -->
            <div class="flex justify-between">
              <div class="font-semibold text-sm text-slate-500">Start Date</div>
              <div class="text-sm text-neutral-900">
                {{
                  item.TimeTracker
                    ? helpers.formatDateToDDMMMYYYY(item.TimeTracker.started_at)
                    : ""
                }}
              </div>
            </div>

            <!-- Complete Date -->
            <div class="flex justify-between">
              <div class="font-semibold text-sm text-slate-500">
                Complete Date
              </div>
              <div class="text-sm text-neutral-900">
                {{
                  item.TimeTracker
                    ? helpers.formatDateToDDMMMYYYY(
                        item.TimeTracker.completed_at
                      )
                    : ""
                }}
              </div>
            </div>

            <!-- Hours Worked -->
            <div class="flex justify-between">
              <div class="font-semibold text-sm text-slate-500">
                Hours Worked
              </div>
              <div class="text-sm text-neutral-900">
                {{
                  item.TimeTracker
                    ? helpers
                        .convertSecondsToDecimalHours(
                          item.TimeTracker.seconds_elapsed
                        )
                        .toFixed(2)
                    : ""
                }}
                Hrs
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Botones de Acción -->
      <div
        v-if="status === 'Submitted' && user_type === 1"
        class="p-4 flex flex-col gap-2"
      >
        <button
          @click="rejectTimesheet"
          class="w-full px-4 py-2 bg-red-500 text-white rounded-lg font-semibold text-center"
        >
          Reject Timesheet
        </button>
        <button
          @click="aproveTimesheet"
          class="w-full px-4 py-2 bg-blue-500 text-white rounded-lg font-semibold text-center"
        >
          Approve Timesheet
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import LoaderSpinner from "../../CircularLoader.vue";
import helpers from "../../../helpers/index.js";
import { useRoute } from "vue-router";

export default {
  components: {
    LoaderSpinner,
  },
  props: {
    isOpen: Boolean,
    id_timesheet: String,
    status: String,
  },
  emits: ["close"],

  setup(props, { emit }) {
    const store = useStore();
    const flagLoading = ref(true);
    const data = ref("");
    const user_type = computed(() => store.state.user.type);
    const isMobileView = computed(() => store.getters.isMobileView);
    const route = useRoute();
    const id_project = route.params.id;

    function closeModal() {
      emit("close");
    }

    async function aproveTimesheet() {
      flagLoading.value = true;
      const data = {
        id_timesheet: props.id_timesheet,
        status: "Approved",
      };
      const resp = await store.dispatch("updateTimesheetStatus", data);

      if (resp.ok) {
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "timesheets",
          searchType: ["Submitted"],
          id_project
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    async function rejectTimesheet() {
      flagLoading.value = true;
      const data = {
        id_timesheet: props.id_timesheet,
        status: "Rejected",
      };
      const resp = await store.dispatch("updateTimesheetStatus", data);

      if (resp.ok) {
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "timesheets",
          searchType: ["Submitted"],
          id_project
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    onMounted(async () => {
      await store
        .dispatch("fetchTimesheet", { id_timesheet: props.id_timesheet })
        .then((dataFetched) => {
          data.value = dataFetched;
        });

      flagLoading.value = false;
    });

    return {
      closeModal,
      user_type,
      flagLoading,
      data,
      helpers,
      aproveTimesheet,
      rejectTimesheet,
      isMobileView,
    };
  },
};
</script>
  
  <style scoped>
/* Add necessary styles */
</style>
  