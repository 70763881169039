export default {
  async showSnackbar({ commit }, message) {
    commit("setSnackbar", { text: message, enabled: true });
  },
  async showLoader ({commit}) {
    commit("setLoader");
  },
  async hideLoader ({commit}) {
    commit("clearLoader");
  },
  
};
