import api from "../../api";
export default {
  async addTimesheet({ commit }, data) {
    try {
      const response = await api.post(
        "/new-kambuchi/timesheets/add-timesheets",
        data
      );

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while adding invoice",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in addInvoice action:", error);
      throw error;
    }
  },

  async fetchTimesheet(_, { id_timesheet }) {
    try {
      const response = await api.get("/new-kambuchi/timesheets/get-timesheet", {
        id: id_timesheet,
      });
      if (response.ok) {
        return response.timesheet;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchResourcesInExpense(_, { id_expense }) {
    try {
      const response = await api.get("/new-kambuchi/users/in-expense", {
        id_expense,
      });
      if (response.ok) {
        console.log("la response de fetch resources for task", response);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async updateTimesheetStatus({ commit }, data) {
    try {
      const response = await api.put(
        `/new-kambuchi/timesheets/change-status`,
        data
      );

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: response.message,
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in updateTimesheetStatus action:", error);
      throw error;
    }
  },

  async deleteTimesheet({ commit }, data) {
    try {
      const response = await api.put(
        `/new-kambuchi/timesheets/delete-timesheet`,
        data
      );

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while deleting timesheet",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in deleteTimesheet action:", error);
      throw error;
    }
  },

  async fetchTimesheetStatus(_, id_timesheet) {
    try {
      const response = await api.get("/new-kambuchi/timesheets/get-timesheet-status", {
        id_timesheet,
      });
      if (response.ok) {
        return response.status;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
};
