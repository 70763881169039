<template>
  <div>
    <div
      class="w-full h-16 px-6 py-3 bg-white justify-end items-center gap-[710px] inline-flex"
    >
      <!-- Elementos de escritorio -->
      <div class="w-full justify-end items-center gap-[30px] flex">
        <!-- Temporizador -->
        <div
          v-if="isTimerActive"
          class="w-[200px] h-10 pl-4 pr-5 py-2.5 bg-slate-100 rounded-lg shadow border border-blue-700 justify-center items-center gap-2 flex"
        >
          <img
            @click="stopTimer"
            v-if="elapsedTimeOnlySeconds > 0"
            src="/icons/global-timer/stop.svg"
            class="w-5 h-5 relative pointer-events-auto cursor-pointer"
          />
          <img
            @click="startTimer"
            v-if="isPaused"
            src="/icons/nav-bar/play.svg"
            class="w-5 h-5 relative pointer-events-auto cursor-pointer"
          />
          <img
            @click="pauseTimer"
            v-else
            src="/icons/global-timer/pause_grey.svg"
            class="w-5 h-5 relative pointer-events-auto cursor-pointer"
          />
          <div
            class="text-blue-700 text-base font-medium font-['Inter'] leading-normal"
          >
            {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }} :
            {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }} :
            {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}
          </div>
        </div>
        <button
          v-else
          @click="toggleModalSTT"
          class="w-[200px] pl-4 pr-5 py-2.5 rounded-lg shadow border border-slate-500 justify-center items-center gap-2 flex"
        >
          <img class="w-9 h-9 relative" src="/icons/nav-bar/play.svg" />
          <div
            class="text-slate-500 text-base font-medium font-['Inter'] leading-normal"
          >
            Time Tracker
          </div>
        </button>

        <!-- Botones adicionales -->
        <div class="justify-start items-center gap-[15px] flex">
          <div
            class="bg-slate-100 rounded-lg justify-start items-center gap-4 flex"
          >
            <button
              class="p-2.5 rounded-lg justify-center items-center gap-2 flex"
            >
              <img class="w-5 h-5 relative" src="/icons/nav-bar/question.svg" />
            </button>
          </div>
          <div class="relative">
            <div
              class="bg-slate-100 rounded-lg justify-start items-center gap-4 flex"
            >
              <button
                class="p-2.5 rounded-lg justify-center items-center gap-2 flex relative"
                @click="toggleNotifications"
              >
                <img
                  class="w-5 h-5 relative"
                  src="/icons/nav-bar/notification.svg"
                />

                <!-- 🔴 Badge de notificaciones no leídas -->
                <span
                  v-if="unreadCount > 0"
                  class="absolute top-0 right-0 transform translate-x-2 -translate-y-2 bg-red-500 text-white text-xs font-bold px-2 py-0.5 rounded-full"
                >
                  {{ unreadCount }}
                </span>
              </button>
            </div>
          </div>
        </div>

        <!-- Foto de perfil con menú desplegable -->
        <div class="relative" ref="dropdownWrapper">
          <div
            @click="toggleDropdown"
            class="flex-col justify-start items-start inline-flex cursor-pointer"
          >
            <img
              class="w-10 h-10 rounded-[200px]"
              :src="photo ? photo : 'https://via.placeholder.com/40x40'"
            />
          </div>
          <!-- Menú desplegable -->
          <div
            v-if="showProfileMenu"
            class="absolute right-0 mt-2 w-40 bg-white rounded-lg shadow-lg border border-gray-200"
          >
            <ul class="py-2">
              <li
                @click="goToProfile"
                class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              >
                Edit Profile
              </li>
              <li
                @click="toggleAuthentication"
                class="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              >
                Log Out
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Modales -->
    <StartTimeTracker
      v-if="showModalStart"
      :isOpen="showModalStart"
      @close="showModalStart = false"
    />
    <StopTimerModal
      v-if="showModalStop"
      :isOpen="showModalStop"
      @close="showModalStop = false"
    />
    <NotificationList
      v-if="showNotifications"
      :isOpen="showNotifications"
      @close="showNotifications = false"
    />
  </div>
</template>


<script>
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import StartTimeTracker from "../GlobalTimer/StartTimeTracker.vue";
import NotificationList from "@/components/Notifications/NotificationList.vue";
import { /*db*/ auth } from "../../firebase/firebaseConfig.js";
// import { doc, /*onSnapshot*/ } from "firebase/firestore";
import StopTimerModal from "../GlobalTimer/StopTimer.vue";
import { useRouter } from "vue-router";

export default {
  components: {
    StartTimeTracker,
    StopTimerModal,
    NotificationList,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const elapsedTimeOnlySeconds = computed(
      () => store.getters.elapsedTimeOnlySeconds
    );
    const isTimerActive = computed(() => store.getters.isTimerActive);
    const showModalStart = ref(false);
    const showModalStop = ref(false);
    const showNotifications = ref(false);
    const currentUser = auth.currentUser;
    // const userTimerDocRef = doc(db, "user-timer", currentUser.uid);
    const isPaused = computed(() => store.getters.isPaused);
    const stopPushed = ref(null);
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const usertype = computed(() => store.state.user.type);
    const photo = computed(() =>
      store.state.user ? store.state.user.photo : ""
    );
    const notifications = computed(() => store.getters.notificationsList);

    // Contar notificaciones no leídas
    const unreadCount = computed(
      () => notifications.value.filter((n) => !n.is_read).length
    );
    const isAuthenticated = computed(() => store.state.loggedUser);
    const toggleAuthentication = async () => {
      if (isAuthenticated.value) {
        await store.dispatch("logOut");
        router.push({ name: "login" });
      } else {
        router.push({ name: "login" });
      }
    };

    const showProfileMenu = ref(false); // Estado para el menú desplegable
    const dropdownWrapper = ref(null);

    const toggleModalSTT = () => {
      showModalStart.value = !showModalStart.value;
    };

    const toggleModalStop = () => {
      showModalStop.value = !showModalStop.value;
    };

    const toggleNotifications = async () => {
      await store.dispatch("fetchNotfications", {
        page: 1,
        limit: 100,
        id_user: currentUser.uid,
        search: "",
        types: ["action", "info"],
      });
      showNotifications.value = !showNotifications.value;
    };

    const toggleDropdown = () => {
      showProfileMenu.value = !showProfileMenu.value;
    };

    const goToProfile = () => {
      showProfileMenu.value = false;
      router.push({
        name: "edit-resource",
        params: { id: currentUser.uid },
      });
    };

    const startTimer = async () => {
      await store.dispatch("startTimer", {
        startWithTime: elapsedTimeOnlySeconds.value,
      });
      await store.dispatch("updateElapsedTime");
    };

    const pauseTimer = async () => {
      await store.dispatch("pauseTimer", { fromButton: true });
    };

    const stopTimer = async () => {
      stopPushed.value = true;
      const previousElapsedTime = elapsedTimeOnlySeconds.value;

      const data = {
        ticket: selectedTakeaway.value.ticket,
        elapsedHours: formatTime(previousElapsedTime).elapsedHours,
        elapsedMinutes: formatTime(previousElapsedTime).elapsedMinutes,
        elapsedSeconds: formatTime(previousElapsedTime).elapsedSeconds,
        isGlobal: true,
        fromButton: true,
      };

      await store.dispatch("pauseTimer", data);

      toggleModalStop();
    };

    const formatTime = (seconds) => {
      const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

      const elapsedHours = Math.floor(seconds / 3600);
      const elapsedMinutes = Math.floor((seconds % 3600) / 60);
      const elapsedSeconds = seconds % 60;

      return {
        elapsedHours: addLeadingZero(elapsedHours),
        elapsedMinutes: addLeadingZero(elapsedMinutes),
        elapsedSeconds: addLeadingZero(elapsedSeconds),
      };
    };

    const handleClickOutside = (event) => {
      if (
        dropdownWrapper.value &&
        !dropdownWrapper.value.contains(event.target)
      ) {
        showProfileMenu.value = false;
      }
    };

    onMounted(async() => {
      await store.dispatch("permissions");
      if (isTimerActive.value) {
        isPaused.value = false;
      }

      document.addEventListener("click", handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    // onSnapshot(userTimerDocRef, async (doc) => {
    //   if (doc.exists()) {
    //     const dataDoc = doc.data();
    //     console.log("🚀 ~ onSnapshot ~ dataDoc: - navbar desktop", dataDoc)
    //     const isNewPausedState = dataDoc.isTimerPaused;
    //     isPaused.value = isNewPausedState;

    //     if (dataDoc.stop) {
    //       isPaused.value = true;
    //       await store.dispatch("pauseTimer", { firestoreFlag: true });
    //       return;
    //     }
    //     if (dataDoc.isTimerActive) {
    //       if (!isNewPausedState) {
    //         await store.dispatch("startTimer", {
    //           startWithTime: elapsedTimeOnlySeconds.value,
    //           ticket: selectedTakeaway.value.ticket,
    //           firestoreFlag: true,
    //         });
    //         await store.dispatch("updateElapsedTime");
    //       } else {
    //         await store.dispatch("pauseTimer", { firestoreFlag: true });
    //       }
    //     }
    //   }
    // });

    return {
      toggleAuthentication,
      isAuthenticated,
      toggleModalSTT,
      toggleModalStop,
      showModalStart,
      showModalStop,
      elapsedTimeOnlySeconds,
      formatTime,
      startTimer,
      pauseTimer,
      stopTimer,
      selectedTakeaway,
      isTimerActive,
      isPaused,
      toggleDropdown,
      goToProfile,
      showProfileMenu,
      usertype,
      photo,
      dropdownWrapper,
      showNotifications,
      toggleNotifications,
      unreadCount
    };
  },
};
</script>
