import api from "../../api";

export default {
  async addClient({ commit }, data) {
    try {
      const response = await api.post("new-kambuchi/clients/add-client", data);

      if (response.ok) {
        return response;
      } else {
        if (response.error.includes("phone_number_UNIQUE")) {
          commit("setSnackbar", {
            text: "Phone number is in use, please use another.",
            enabled: true,
          });
          return response;
        } else if (response.error.includes("email_UNIQUE")) {
          commit("setSnackbar", {
            text: "Email  is in use, please use another.",
            enabled: true,
          });
          return response;
        } else {
          commit("setSnackbar", {
            text: "Unexpected error while adding client",
            enabled: true,
          });
          return response;
        }
      }
    } catch (error) {
      console.error("Error in addClient action:", error);
      throw error;
    }
  },

  async fetchClient({ commit }, { id_client }) {
    try {
      const response = await api.get("/new-kambuchi/clients/get-client", {
        id: id_client,
      });
      commit("setDetails", response.dataValues);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchAllClients({ commit }) {
    try {
      const response = await api.get("/new-kambuchi/clients/all");
      commit("setDetails", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async editClient({ commit }, data) {
    try {
      const response = await api.put(`/new-kambuchi/clients/edit-client`, data);

      if (response.message) {
        return response;
      } else {
        if (response.error.includes("phone_number_UNIQUE")) {
          commit("setSnackbar", {
            text: "Phone number is already in use, please use another.",
            enabled: true,
          });
          return response;
        } else if (response.error.includes("email_UNIQUE")) {
          commit("setSnackbar", {
            text: "Email is already in use, please use another.",
            enabled: true,
          });
          return response;
        } else {
          commit("setSnackbar", {
            text: "Unexpected error while editing client",
            enabled: true,
          });
          return response;
        }
      }
    } catch (error) {
      console.error("Error in editClient action:", error);
      throw error;
    }
  },

  async deleteClient({ commit }, data) {
    try {
      const response = await api.put(`/new-kambuchi/clients/delete-client`, data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while deleting client",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in deleteClient action:", error);
      throw error;
    }
  },
};
