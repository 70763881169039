import debounce from "lodash.debounce";
export default {
  convertSeconds(totalSeconds) {
    const numberSeconds = Number(totalSeconds);

    if (isNaN(numberSeconds) || numberSeconds < 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours = Math.floor(numberSeconds / 3600);
    const minutes = Math.floor((numberSeconds % 3600) / 60);
    const seconds = numberSeconds % 60;

    return { hours, minutes, seconds };
  },
  convertSecondsToDecimalHours(totalSeconds) {
    const numberSeconds = Number(totalSeconds);

    if (isNaN(numberSeconds) || numberSeconds < 0) {
      return 0;
    }

    const decimalHours = numberSeconds / 3600;
    return decimalHours;
  },
  convertToDecimalHours(hours, minutes, seconds) {
    const decimalHours =
      parseInt(hours) + parseInt(minutes) / 60 + parseInt(seconds) / 3600;
    return decimalHours;
  },

  formatPhoneNumber(phoneNumber) {
    if (phoneNumber && phoneNumber.length === 10) {
      return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
        3,
        6
      )}-${phoneNumber.substring(6)}`;
    }
    return phoneNumber;
  },

  formatDateToMMDDYYYY(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${month}-${day}-${year}`;
  },

  formatDateToDDMMMYYYY(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    if (isNaN(date)) {
      return "";
    }

    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  },
  
  formatDateNoTimezone(dateString) {
    if (!dateString) {
      return "";
    }
  

    const date = new Date(dateString + "T00:00:00Z");
  
    if (isNaN(date)) {
      return "";
    }
  
    const day = date.getUTCDate();
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
  },
  

  createDebouncedFetch(store, type, searchType, id_project = null) {
    const fetch = async (query) => {
      //esto es para tasks
      let type4search = "";
      if (type == "tasks") {
        type4search =
          searchType.value == "Active"
            ? ["In progress", "Not Started", "Missed"]
            : ["Complete"];
      } else if (type == "users") {
        type4search = searchType.value == "Employees" ? 2 : 3;
      }

      try {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: type,
          search: query.target.value,
          searchType: type4search != "" ? type4search : searchType.value,
          id_project: id_project,
        });
      } catch (error) {
        console.error("Error fetching :" + type, error);
      }
    };

    return debounce(fetch, 300);
  },

  capitalize(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  truncatedFileName(fileName) {
    const maxLength = 17;
    return fileName.length > maxLength
      ? fileName.substring(0, maxLength) + "..."
      : fileName;
  },
};


