import api from "../../api";
import {
  checkNotificationPermission,
  requestNotificationPermission,
} from "@/firebase/firebaseConfig";
export default {
  async saveToken({ state }, token) {
    try {
      const response = await api.post("/new-kambuchi/notification-tokens/add", {
        id_user: state.user.uid,
        token,
      });
      if (response.ok) {
        return response.data;
      }
    } catch (error) {
      console.log("🚀 ~ saveToken ~ error:", error);
      console.error(error);
    }
  },

  async fetchToken({ state }) {
    try {
      const response = await api.get("/new-kambuchi/notification-tokens", {
        id_user: state.user.uid,
      });
      if (response.ok) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async permissions({ dispatch }) {
    const havePermission = await checkNotificationPermission();
    console.log("🚀 ~ unsubscribeAuth ~ havePermission:", havePermission);
    if (havePermission !== "granted") {
      const token = await requestNotificationPermission();
      if (token) {
        dispatch("saveToken", token);
      }
    }
  },
};
