import api from "../../api";
export default {
  async addBill({ commit }, data) {
    try {
      const response = await api.post("/new-kambuchi/bills/add-bills", data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while adding bill",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in addBill action:", error);
      throw error;
    }
  },

  async fetchBill(_, { id_bill }) {
    try {
      const response = await api.get("/new-kambuchi/bills/get-bill", {
        id: id_bill,
      });
      if (response.ok) {
        return response.bill;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchResourcesInExpense(_, { id_expense }) {
    try {
      const response = await api.get("/new-kambuchi/users/in-expense", {
        id_expense,
      });
      if (response.ok) {
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async updateBillStatus({ commit }, data) {
    try {
      const response = await api.put(`/new-kambuchi/bills/change-status`, data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: response.message,
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in updateBillStatus action:", error);
      throw error;
    }
  },

  async deleteBill({ commit }, data) {
    try {
      const response = await api.put(`/new-kambuchi/bills/delete-bill`, data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while deleting bill",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in deleteBill action:", error);
      throw error;
    }
  },

  async fetchBillStatus(_, id_bill) {
    try {
      const response = await api.get("/new-kambuchi/bills/get-bill-status", {
        id_bill,
      });
      if (response.ok) {
        return response.status;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
};
