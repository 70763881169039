<template>
  <Loading v-if="flaLoading" />
  <div class="relative" ref="menuContainer" v-else>
    <img
      src="/icons/projects/action-dots.svg"
      class="w-5 h-5 pointer-events-auto cursor-pointer"
      @click.stop="toggleMenu"
    />
    <div
      v-if="showMenu"
      class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-10"
    >
      <ul>
        <li
          @click="handleAction('Edit')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          Edit resource
        </li>
        <li
          @click="handleAction('Send')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          Send registration OTP
        </li>
        <li
          @click="handleAction('Delete')"
            class="px-4 py-2 cursor-pointer hover:bg-red-400 bg-red-200"
        >
          Delete
        </li>
      </ul>
    </div>
    <ModalDelete
      v-if="showDeletedModal"
      :from="'user'"
      :item="item"
      :isOpen="showDeletedModal"
      @close="showDeletedModal = false"
    />
  </div>
</template>
    
    <script>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";
import menuState from "../../const/taskMenuState";
import { useStore } from "vuex";
import Loading from "../CircularLoader.vue";
import ModalDelete from "../Projects/DeleteModal.vue";

export default {
  props: {
    item: Object,
  },
  components: { Loading, ModalDelete },
  setup(props) {
    const showMenu = ref(false);
    const router = useRouter();
    const store = useStore();
    const menuContainer = ref(null);
    const flaLoading = ref(false);
    const showDeletedModal = ref(false);

    const toggleMenu = () => {
      const newMenuState = !showMenu.value;
      menuState.setOpenMenuId(newMenuState ? props.item.id_user : null);
    };

    const handleAction = async (action) => {
      if (action === "Edit") {
        toggleResourceDetail();
      } else if (action === "Send") {
        sendOtp();
      } else if (action === "Delete") {
        deleteUser();
      }
      showMenu.value = false;
      menuState.setOpenMenuId(null);
    };

    const handleClickOutside = (event) => {
      if (menuContainer.value && !menuContainer.value.contains(event.target)) {
        showMenu.value = false;
        menuState.setOpenMenuId(null);
      }
    };

    watch(
      () => menuState.getOpenMenuId(),
      (newId) => {
        showMenu.value = newId === props.item.id_user;
      }
    );

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    function toggleResourceDetail() {
      router.push({
        name: "edit-resource",
        params: { id: props.item.id_user },
      });
    }

    async function sendOtp() {
      flaLoading.value = true;
      const data = {
        id_user: props.item.id_user,
        email: props.item.email,
      };
      await store
        .dispatch("generateRegistrationOtp", data)
        .then(async (res) => {
          if (res.data.res) {
            flaLoading.value = false;
            await store.commit("setSnackbar", {
              text: "Email with OTP sent successfully",
              enabled: true,
            });
          }
        })
        .catch(() => {
          flaLoading.value = false;
          store.commit("setSnackbar", {
            text: "Error sending OTP, please try again",
            enabled: true,
          });
        });
    }

    function deleteUser() {
      showDeletedModal.value = !showDeletedModal.value;
    }


    return {
      showMenu,
      toggleMenu,
      handleAction,
      menuContainer,
      flaLoading,
      showDeletedModal
    };
  },
};
</script>
    
    <style scoped>
/* Add necessary styles if needed */
</style>
    