import api from "../../api";

export default {
  async fetchNotfications({ commit }, { page, limit, id_user, search, types }) {
    try {
      const params = {
        page,
        limit,
        id_user,
        search,
        types: Array.isArray(types) ? types.join(",") : types, // Convertir a string si es array
      };

      const response = await api.get("/new-kambuchi/notifications", params);

      commit("setNotificationsList", response.data);
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async readNotification({ commit }, data) {
    try {
      const response = await api.put(
        `/new-kambuchi/notifications/read`,
        data
      );

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while updating notification",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in readNotification action:", error);
      throw error;
    }
  },
  async deleteNotification({ commit }, id) {
      try {
        const response = await api.delete(
          `/new-kambuchi/notifications/delete`,
          id
        );
  
        if (response.ok) {
         
          return response;
        } else {
          commit("setSnackbar", {
            text: "Unexpected error while deleting notification",
            enabled: true,
          });
          return response;
        }
      } catch (error) {
        console.error("Error in deleteService action:", error);
        throw error;
      }
    },
};
