import { ref, onUnmounted, computed } from "vue";
import { onSnapshot, doc } from "firebase/firestore";
import { auth, db } from "../firebase/firebaseConfig.js";
import store from "../store";

export function useTimerSnapshot() {
  const isPaused = ref(false);

  // Validate store availability
  if (!store) {
    console.error("Store is not available in useTimerSnapshot.");
    return; // Exit the composable
  }

  // Validate authenticated user and UID
  if (!auth.currentUser || !auth.currentUser.uid) {
    console.error(
      "User is not authenticated or UID is not available in useTimerSnapshot."
    );
    return; // Exit the composable
  }

  let userTimerDocRef;
  try {
    userTimerDocRef = doc(db, "user-timer", auth.currentUser.uid);
  } catch (error) {
    console.error("Error creating Firestore document reference:", error);
    return; // Exit the composable
  }

  const elapsedTimeOnlySeconds = computed(() => {
    try {
      return store.getters.elapsedTimeOnlySeconds;
    } catch (error) {
      console.error(
        "Error accessing elapsedTimeOnlySeconds from getters:",
        error
      );
      return 0; // Default value in case of error
    }
  });

  let unsubscribe = null;

  const startListening = () => {
    if (!userTimerDocRef) {
      console.error("Document reference is not available to start listening.");
      return;
    }

    try {
      unsubscribe = onSnapshot(userTimerDocRef, async (doc) => {
        if (doc.exists()) {
          const dataDoc = doc.data();

          if (dataDoc.isTimerActive && !store.state.tracker.isTimerActive) {
            store.dispatch("getActiveTimer", auth.currentUser.uid);
          } else if (dataDoc.stop && dataDoc.currentAction === "stop") {
            store.commit("clearIsTimerActive");
          }

          const isNewPausedState = dataDoc.isTimerPaused;
          isPaused.value = isNewPausedState;
          if (isNewPausedState) {
            await store.commit("setIsPaused");
          }
          if (dataDoc.stop) {
            isPaused.value = true;
            await store.dispatch("pauseTimer", { firestoreFlag: true });
            return;
          }

          if (dataDoc.isTimerActive) {
            if (!isNewPausedState) {
              await store.dispatch("startTimer", {
                startWithTime: elapsedTimeOnlySeconds.value,
                ticket: dataDoc.ticket || null,
                firestoreFlag: true,
              });
              await store.dispatch("updateElapsedTime");
              await store.commit("clearIsPaused");
            } else {
              await store.dispatch("pauseTimer", { firestoreFlag: true });
            }
          }
        }
      });
    } catch (error) {
      console.error("Error setting up onSnapshot listener:", error);
    }
  };

  const stopListening = () => {
    if (unsubscribe) {
      try {
        unsubscribe();
        unsubscribe = null;
      } catch (error) {
        console.error("Error stopping Firestore listener:", error);
      }
    }
  };

  // Clean up subscription when the component is unmounted
  onUnmounted(() => {
    stopListening();
  });

  return {
    startListening,
    stopListening,
  };
}
