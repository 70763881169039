import tracker from "./tracker";
import app from "./app";
import pagination from "./pagination";
import actionDetails from "./action-details";
import notifications from "./notifications";

export default {
  ...tracker,
  ...app,
  ...pagination,
  ...actionDetails,
  ...notifications,
};
