import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDaDN18QKL69uAnl9wq6sNjQ4DmBm15Kro",
  authDomain: "kambuchi-58cd9.firebaseapp.com",
  projectId: "kambuchi-58cd9",
  storageBucket: "kambuchi-58cd9.appspot.com",
  messagingSenderId: "635620925332",
  appId: "1:635620925332:web:c3e0115f68924f4454b981",
  measurementId: "G-9S3VWKHYM5",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const messaging = getMessaging(app);

// Check permissions
const checkNotificationPermission = () => {
  const permission = Notification.permission;
  return permission;
};

// Request notification permission and get token
const requestNotificationPermission = async () => {
  const messaging = getMessaging();
  const result = await Notification.requestPermission();
  if (result === "granted") {
    const tkn = await getToken(messaging, {
      vapidKey: process.env.VUE_APP_VAP_ID,
    });
    return tkn;
  }
};

// Listen for foreground messages
onMessage(messaging, (payload) => {
  console.log("Notification received in the foreground:", payload);
});

export {
  db,
  app,
  auth,
  requestNotificationPermission,
  checkNotificationPermission,
};
